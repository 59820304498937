<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body>
            <b-card-body>
                <b-row>

                    <b-col md="12">
                        <span @click="$router.go(-1)">
                            <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
                        </span>
                        <h4 v-if="device" class="card-title float-left"> 
                            {{device.site.site_name | capitalize }} | {{ device.device_name | capitalize }}
                        </h4>                        
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end mobile-view">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                                placeholder="Search..."
                                @input="filterTable"
                            />

                            <b-button variant="warning" class="mobile-margin-bottom" @click="openModal(null)">
                                <span class="text-nowrap">Add Sensor</span>
                            </b-button>

                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
            >

                <template #cell(sensor_name)="items">
                    <p class="wordBreak">{{ items.item.sensor_name | capitalize}}</p>
                </template>
                
                <template #cell(status)="items">
                    <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize">
                        {{ items.item.status}}
                    </b-badge>
                </template>

                <template #cell(created_at)="items">
                    <span>{{ items.item.created_at | dateTime}}</span>
                </template>

                <template #cell(min_level_value_in_mm)="items">
                    <span v-if="items.item.min_level_value_in_mm">{{ items.item.min_level_value_in_mm }} mm</span>
                    <span v-else>N/A</span>
                </template>

                <template #cell(max_level_value_in_mm)="items">
                    <span v-if="items.item.max_level_value_in_mm">{{ items.item.max_level_value_in_mm }} mm</span>
                    <span v-else>N/A</span>
                </template>

                <template #cell(percent_trigger_value)="items">
                    <span v-if="items.item.percent_trigger_value">{{ items.item.percent_trigger_value }}%</span>
                    <span v-else>N/A</span>
                </template>

                <template #cell(notifications)="items">
                    <span>{{ items.item.notifications | capitalize }}</span>
                </template>

                <template #cell(actions)="items">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <!-- 3 dot menu -->
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <!-- Edit button -->
                        <b-dropdown-item @click="openModal(items.item._id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <!-- Change Status button -->
                        <b-dropdown-item @click="statusChange(items.item)">
                            <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
                            <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
                            <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
                        </b-dropdown-item>

                        <!-- Delete button -->
                        <b-dropdown-item @click="deleteItem(items.item._id)">
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>

                    </b-dropdown> 
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
        
        <!-- Add Sensor Modal -->
        <b-modal 
            id="add-sensor" centered ref="add-sensor" cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            :title="title"
            @show="resetForm"
            @hidden="resetForm"
            @ok="addSensor"
            no-close-on-backdrop
        >
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Gateway Id" class="required">
                            <b-form-input placeholder="Enter Gateway Id" v-model="form.gateway_id" disabled/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Sensor Id" class="required">
                            <b-form-input placeholder="Enter Sensor Id" v-model="form.sensor_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Sensor Name" class="required">
                            <b-form-input placeholder="Enter Sensor Name" v-model="form.sensor_name"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Sensor Type" class="required">
                            <b-form-select v-model="form.sensor_type">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                <b-form-select-option :value="type" v-for="(type, index) in sensor_types" :key="index">{{type}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Minimum Level (in mm)" class="required">
                            <b-form-input placeholder="Enter Minimum Level" v-model="form.min_level_value_in_mm"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Maximum Level (in mm)" class="required">
                            <b-form-input placeholder="Enter Maximum Level" v-model="form.max_level_value_in_mm"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Percent Trigger Value" class="required">
                            <b-form-input placeholder="Enter Percent Trigger Value" v-model="form.percent_trigger_value"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="">
                            <b-form-checkbox v-model="form.notifications" value="yes" unchecked-value="no" class="custom-control-warning">
                                Enable Notification / Email
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>      
            </b-form>

        </b-modal>
    
    </div>
</template>

<script>
    import {
        BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';

    export default {
        components: {
            vSelect, BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                sensor_types : ['Toilet Roll Sensor', 'Hand Soap Dispenser Sensor', 'Trash Bin Sensor', 'Other'],

                device : null,

                tableColumns: [
                    { key: 'sensor_id', label: 'Sensor Id', sortable: true , thStyle:  {width: '12%'}},
                    { key: 'sensor_name', label: 'Sensor Name', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'sensor_type', label: 'Sensor Type', sortable: true , thStyle:  {width: '15%'}},
                    { key: 'min_level_value_in_mm', label: 'Min. Level', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'max_level_value_in_mm', label: 'Max. Level', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'percent_trigger_value', label: 'Percent Trigger Value', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'notifications', label: 'Notification', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'created_at', label: 'Added On', sortable: false , thStyle:  {width: '10%'}},
                    { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},
                ], 

                // b-table and pagination options
                    items           : [],
                    isSortDirDesc   : true,
                    perPageOptions  : [10, 20, 50, 100],
                    perPage         : 10,
                    sortBy          : 'id',
                    totalRecords    : 0,
                    currentPage     : 1,
                    searchQuery     : '',
                    from            : null,
                    to              : null,

                title: null,
                
                // Sensor vars
                    form : {
                        id          : '',
                        device_id   : this.$route.params.id,
                        sensor_name : '',
                        sensor_type : '',
                        sensor_id   : '',
                        gateway_id  : '',
                        min_level_value_in_mm  : '',
                        max_level_value_in_mm  : '',
                        percent_trigger_value  : '',
                        notifications  : 'yes',
                    }
            }
        },

        methods : {
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        device_id     : this.$route.params.id,
                    },
                    api: '/api/sensor-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            resetForm(){
                this.form.id          = '';
                this.form.device_id   = this.$route.params.id;
                this.form.sensor_name = '';
                this.form.sensor_type = '';
                this.form.sensor_id   = '';
                this.form.min_level_value_in_mm   = '';
                this.form.max_level_value_in_mm   = '';
                this.form.percent_trigger_value   = '';
                this.form.notifications   = 'yes';
            },

            openModal(id){
                this.showDismissibleAlert = false;
                this.title = id ? 'Edit Sensor' : 'Add Sensor';

                if(id){
                    this.getSensorDetails(id);
                }
                this.$refs['add-sensor'].show();
            },

            getSensorDetails(id){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : id
                    },
                    api:"/api/sensor-detail",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        var data = this.$store.getters.getResults.data;

                        this.form.id          = data._id;
                        this.form.sensor_name = data.sensor_name;
                        this.form.sensor_id   = data.sensor_id;
                        this.form.sensor_type = data.sensor_type;
                        this.form.min_level_value_in_mm = data.min_level_value_in_mm;
                        this.form.max_level_value_in_mm = data.max_level_value_in_mm;
                        this.form.percent_trigger_value = data.percent_trigger_value;
                        this.form.notifications = data.notifications;
                    }
                });
            },

            addSensor(e){
                e.preventDefault();
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form
                    },
                    api:this.form.id ? "/api/edit-sensor" : "/api/add-sensor",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.$refs['add-sensor'].hide();
                        this.successAlert();
                        this.filterTable();
                    }
                });
            },

            getDeviceDetails(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : this.$route.params.id
                    },
                    api:"/api/device-detail",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.device = this.$store.getters.getResults.data;
                        this.form.gateway_id = this.device.device_id
                    }
                });
            },

            statusChange(item){
                if (item.status == 'active') {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-sensor-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.successAlert();
                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            deleteItem(id){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-sensor-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

        },
        mounted(){
            this.getDeviceDetails();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
